import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../security/AuthContext';
import { CurrentUserContext } from '../security/CurrentUserContext';
import styled from 'styled-components';
import Cookies from 'js-cookie';

export default function LoginComponent() {
    // const authContext = useAuth();
    const navigate = useNavigate();

    const {
        currentUserState,
        actions: {
            receiveUserInfoFromServer,
            receiveUserAuthFromServer,
            removeCurrentUser
        }
    } = useContext(CurrentUserContext);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [loginFailed, setLoginFailed] = useState(false);

    const [passwordResetSent, setPasswordResetSent] = useState(false);

    useEffect(() => {
        if(currentUserState.authenticated){
            navigate("/admin")
            // console.log("currentUserState",currentUserState)
        }    
    }, [currentUserState]);

    const handleForgotPassword = (event) => {
        if (username.includes('@')) {
            console.log('good email');

            event.preventDefault();
            fetch(`${API_BASE_URL}/api/forgot-password`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: username })
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        alert('error');
                    }
                })
                .then((response_j) => {
                    setPasswordResetSent(true);
                })
                .catch((error) => console.log(error));
        } else {
            alert('please provide valid email');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // const token = localStorage.getItem('token');

        fetch(`${API_BASE_URL}/api/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password }),
            credentials: 'include'
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    alert('Invalid credentials');
                }
            })
            .then((response_j) => {
                
                // localStorage.setItem('token', response_j.token);
                receiveUserInfoFromServer(response_j.data);
                receiveUserAuthFromServer({ authenticated: true });

                

                // localStorage.setItem('authToken', data.token);
                // if (response_j && response_j.token) {  // Ensure the token is present in the response
                    // Set the token as a cookie on the client side
    
                    // Optionally, store user data and authentication state
                    // receiveUserInfoFromServer(response_j.data);
                    // receiveUserAuthFromServer({ authenticated: true });
                // }
                // window.location.href = "/admin"
            })
            .catch((error) => console.log(error));
    };

    return (
        <>
            <section className="w3l-form-2">
                <div className="">
                    <div className="header">
                        <Link to="/" className="brand-logo">BROWEN</Link>
                        <Link to="/register" className="signin">Register</Link>
                    </div>
                    <div className="wrapper">
                        <div className="text-main">
                            <div className="main1">
                                <div className="tagline">
                                    <p className="form-tagline">Login to your account Now</p>
                                    <h1 className="head-form">Welcome to the BROWEN CRM</h1>
                                    <p className="form-para1">
                                        Access property acquisition tools, manage leads, and edit backend content in one centralized platform.

                                        Login to continue
                                        Please enter your credentials to access the dashboard.

                                        Need help? Contact Support or Reset Password

                                        Access is restricted to authorized users only. Unauthorized use will be monitored and may lead to disciplinary action.  </p>
                                </div>
                            </div>
                            <div className="main2">
                                {loginFailed && <div style={{color: "red"}}>Login attempt failed. Please make sure you have used the correct credentials.</div>}
                                {/* <div > */}
                                <form onSubmit={handleSubmit} action="#" autoComplete="on" className="sign-main">
                                    <p className="signup">Login to your account</p>
                                    <p className="create">Welcome back! Login to your account</p>
                                    <div className="sign-up-form">
                                        <label htmlFor="username"></label>
                                        <input 
                                            id="username"
                                            type="text"
                                            name="username"
                                            value={username}
                                            className="form-type"
                                            placeholder="Your username"
                                            required
                                            autoComplete="username"
                                            onChange={(event) => {setUsername(event.target.value)}} 
                                        />
                                        <label htmlFor="password"></label>
                                        <input 
                                            id="password"
                                            type="password" 
                                            name="password"
                                            value={password} 
                                            className="form-type" 
                                            placeholder="Your password" 
                                            required 
                                            autoComplete="current-password"  
                                            onChange={(event) => {setPassword(event.target.value)}} 
                                        />

                                        {/* <div>
                                            <label className="check-remember container">
                                                <input type="checkbox" className="form-check" />
                                                <span className="checkmark"></span>
                                                <p className="privacy-policy">Keep me logged in</p>
                                            </label>
                                            <div className="clear"></div>
                                        </div> */}
                                        {/* <button className="create-account" onClick={handleSubmit} >Login account</button> */}
                                        <button className="create-account" type="submit">Login account</button>
                                        <p className="account text-center">Don't have an account? Go to <Link to="/register" className="register">Register</Link> </p>
                                        <p className="account text-center">Forgot Password? <Link onClick={(event) => handleForgotPassword(event)} className="register">Click Here</Link> </p>                                       
                                    </div>
                                {/* </div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


